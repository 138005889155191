<template>
    <layout-div>
        <div v-if="isLoading" class="loading-overlay">
            <div class="spinner"></div>
        </div>
        
        <div v-if="!isLoading">
            <div v-if="isNewsModalVisible" class="modal-overlay">
                <div class="modal-container">
                    <div class="modal-header">
                    <h5 class="text-lg font-bold">{{ newsData.latest_news.title }}</h5>
                    <button type="button" class="text-gray-600 hover:text-gray-900" @click="handleNewsModalClose">&times;</button>
                    </div>
                    <div class="modal-body">
                    <p class="text-sm text-gray-700">{{ newsData.latest_news.message }}</p>
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="button" @click="markNewsAsRead">Gelesen</button>
                    </div>
                </div>
            </div>

            <div v-if="isBookingModalVisible" class="modal-overlay">
                <div class="modal-container">
                    <div class="modal-header">
                        <h5 class="text-lg font-bold">Stunden bestätigen für <b>{{ modalData.child_name }}</b></h5>
                        <button type="button" class="text-gray-600 hover:text-gray-900">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-4">
                            <label for="plannedDate" class="block text-sm font-medium text-gray-700">Für das Datum:</label>
                            <input type="text" id="plannedDate" :value="prettifyPlannedDate(modalData.planned_date)" disabled class="input-field" />
                        </div>
                        <div class="mb-4">
                            <label for="startTime" class="block text-sm font-medium text-gray-700">Startzeit:</label>
                            <input type="time" id="startTime" v-model="modalData.start_time" step="900" @change="formatTime('start_time')" class="input-field" />
                        </div>
                        <div class="mb-4">
                            <label for="endTime" class="block text-sm font-medium text-gray-700">Endzeit:</label>
                            <input type="time" id="endTime" v-model="modalData.end_time" step="900" @change="formatTime('end_time')" class="input-field" />
                        </div>
                        <div class="mb-4">
                            <label for="bookingDiffComment" class="block text-sm font-medium text-gray-700">Kommentar zur Abweichung:</label>
                            <textarea id="bookingDiffComment" v-model="modalData.booking_diff_comment" rows="3" @input="sanitizeComment" placeholder="Kommentar zur Abweichung (mind. 5 Zeichen)" class="input-field"></textarea>
                            <small class="text-gray-500">Keine Sonderzeichen erlaubt.</small>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="button" @click="submitBooking" :disabled="!isCommentValid">Abschicken</button>
                        <button type="button" class="button-secondary" @click="reloadPage">Abbrechen</button>
                    </div>
                </div>
            </div>

            <div class="content-container">
                <NavBarComponent @logout="logoutAction" />
                <div class="greeting-text">
                    <p>Guten Tag,<br>{{ user.firstName }}, {{ user.lastName }}</p>
                </div>
            </div>

            <div class="content-container">
                <h4 class="section-heading">Dein Kalender <i class="bi bi-calendar3"></i></h4>
                <div v-for="day in weekDays" :key="day.date" class="mb-6">
                    <div :class="isToday(day.date) ? 'day-label-today' : 'day-label'">
                        {{ formatToLocaleDate(day.date) }}
                    </div>
                    <div class="calendar-container" v-if="day.events.length > 0">
                        <div v-for="event in day.events" :key="event.id" :class="['event-card', isToday(day.date) ? 'event-card-today' : '']">
                            <div :class="['event-title', isToday(day.date) ? 'event-title-today' : '']">
                                {{ event.title }}
                            </div>
                            <div v-html="event.message" class="event-message"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-center mt-4">
                <button @click="goToPreviousWeek" class="button-secondary mx-2">
                    <i class="bi bi-arrow-left"></i> Zurück
                </button>
                <button @click="goToNextWeek" class="button-secondary mx-2">
                    Weiter <i class="bi bi-arrow-right"></i>
                </button>
            </div>

            <div class="content-container">
                <h4 class="section-heading">
                    Dienstplan {{ prettifyPlannedDate(formatDate2PlannedDate(subtractDays(this.selectedDate, 6))) }} bis {{ prettifyPlannedDate(formatDate2PlannedDate(this.selectedDate)) }}
                </h4>
                <div class="table-container">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="table-header">Betreuungskind</th>
                                <th class="table-header">Einteilung</th>
                                <th class="table-header">Einrichtung</th>
                                <th class="table-header">Fachbereich</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in combinedData" :key="item.id">
                                <td class="table-cell" style="font-size:20px;">{{ extractChildName(item.children_name) }}</td>
                                <td class="table-cell" style="font-size:20px;">{{ item.start_datetime }} - {{ item.end_datetime }}</td>
                                <td class="table-cell" style="font-size:20px;">{{ item.children_datum.workingarea.facility }}</td>
                                <td class="table-cell" style="font-size:20px;">
                                    <span :class="['px-2 py-1 rounded-full text-xs font-semibold','bg-green-100 text-green-800']">
                                        {{ item.working_area }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="combinedData.length > 0 && checkAllBookingsFinished()" class="summary-section">
                    <p>Ist-Leistungsstunden/Tag: <b>{{ this.bookingSummary.overall_working_hours || 0 }} Stunden</b></p>
                    <p>Saldo-Anmeldestunden/Tag. Basis {{ this.bookingSummary.registered_working_hours_per_day || 0 }}: <b>{{ this.bookingSummary.registered_working_hours_per_day_diff || 0 }} Stunden</b></p>
                    <p>Saldo-Leistungsstunden/Tag. Basis {{ this.bookingSummary.service_working_hours_per_day || 0 }}: <b>{{ this.bookingSummary.service_working_hours_per_day_diff || 0 }} Stunden</b></p>
                </div>

                <div class="flex justify-center mt-4">
                    <button
                        v-if="combinedData.length > 0 && !checkAllBookingsFinished() && !lockedStatus && !isProcessingEntries"
                        @click="finishBookingForDay"
                        :disabled="checkAllBookingsFinished()"
                        class="button fixed-bottom-button"
                    >
                        Tag abschließen <i class="bi bi-check-circle"></i>
                    </button>
                </div>
            </div>

            <div v-if="lockedStatus" class="dynamic-text mt-2 alert-success">
                <br><p style="color:black;">{{ dynamicText }}</p>
            </div>
            
            <div v-if="isProcessingEntries" class="processing-text mt-2">
                <p>Bitte warten... Die Einträge werden abgeschlossen.</p>
            </div>
        </div>
        <br>
        <footer class="footer mt-8">
            <div class="footer-content text-center text-gray-500">
                <p>&copy; Alle Rechte vorbehalten.</p>
            </div>
        </footer>
    </layout-div>
</template>

<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import NavBarComponent from '../NavBarComponent.vue';

export default {
    name: 'DashboardPage',
    components: {
        LayoutDiv,
        NavBarComponent
    },
    data() {
        return {
            isLoading: true,
            lockedStatus: false,
            dynamicText: 'Stundenbuchen gesperrt.',
            user: {},
            isNewsModalVisible: false,
            isBookingModalVisible: false,
            newsData: {
                latest_news: {
                title: '',
                message: ''
                },
                flag_news_read: 0
            },
            userId: 0,
            combinedData: [],
            selectedDate: new Date(),
            weekDays: [],
            modalData: {
                planned_date: '',
                start_time: '07:00:00',
                end_time: '08:00:00',
                currentBookingId: null,
                child_name: '',
                booking_diff_comment: ''
            },
            messageTitle: '',
            messageContent: '',
            showSuccessAlert: false,
            showAlert: false,
            alertMessage: '',
            bookingSummary: {},
            isProcessingEntries: false,
            isDayClosing: true,
            otpIntervalId: null,
        };
    },
    computed: {
        isCommentValid() {
            return this.modalData.booking_diff_comment.length >= 5;
        }
    },
    created() {
        this.initializeDashboard().then(() => {
            this.isLoading = false;
        })
        .catch(error => {
            console.error("Error during initialization:", error);
            this.isLoading = false;
        });
        this.checkLockedStatus();
        
        this.otpIntervalId = setInterval(this.checkOTPStatus, 60000);
    },
    beforeUnmount() {
        if (this.otpIntervalId) {
            clearInterval(this.otpIntervalId);
        }
    },
    methods: {
        subtractDays(date, days) {
            const result = new Date(date);
            result.setDate(result.getDate() - days);
            return result;
        },
        async checkOTPStatus() {
            try {
                const response = await axios.get(`/users/check-otp-status/${this.userId}`, { withCredentials: true });
                if (response.data && response.data.success === false) {
                    console.warn('OTP ist abgelaufen. Benutzer wird ausgeloggt.');
                    this.logoutAction();
                }
            } catch (error) {
                console.error('Fehler beim Abrufen des OTP-Status:', error);
            }
        },
        reloadPage() {
            window.location.reload();
        },
        async markNewsAsRead() {
            try {
                await axios.post('/users/mark_news_read', { caregiver_id: this.userId }, { withCredentials: true });
                this.isNewsModalVisible = false;
            } catch (error) {
                console.error('Error marking news as read:', error);
            }
        },
        handleNewsModalClose() {
            this.isNewsModalVisible = false;
        },
        async checkLockedStatus() {
            try {
                const response = await axios.get('/users/current', { withCredentials: true });
                this.userId = response.data.id;
                const today = new Date();
                const formattedDate = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + ('0' + today.getDate()).slice(-2);
                const userDataResponse = await axios.get(`/users/get_user_data/${this.userId}/${formattedDate}`, { withCredentials: true });
                if (userDataResponse && userDataResponse.data) {
                    this.lockedStatus = userDataResponse.data.locked_status;
                    this.startProgressAnimation();
                }
            } catch (error) {
                console.error('Error fetching locked status:', error);
            }
        },
        startProgressAnimation() {
            if (this.lockedStatus) {
                let dots = 0;
                this.intervalId = setInterval(() => {
                    dots = (dots + 1) % 4;
                    this.dynamicText = 'Stundenbuchen gesperrt' + '.'.repeat(dots);
                }, 500);
            }
        },
        stopProgressAnimation() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        },
        prettifyPlannedDate(plannedDate) {
            if (!plannedDate || plannedDate.length !== 8) return '';
            const year = plannedDate.substring(0, 4);
            const month = plannedDate.substring(4, 6).replace(/^0+/, '');
            const day = plannedDate.substring(6, 8).replace(/^0+/, '');
            return `${day}.${month}.${year}`;
        },
        openBookingModal(item, plannedDate) {
            this.modalData.planned_date = plannedDate;
            this.modalData.start_time = item.start_datetime;
            this.modalData.end_time = item.end_datetime;
            this.modalData.currentBookingId = item.id;
            this.modalData.child_name = this.extractChildName(item.children_name);
            this.modalData.booking_diff_comment = this.isDayClosing ? 'Kein Kommentar' : '';
        },
        async submitBookingNoDivergence() {
            function convertToFullTimeFormat(time) {
                if (time && /^\d{2}:\d{2}:\d{2}$/.test(time)) {
                    return time;
                }
                if (time && /^\d{2}:\d{2}$/.test(time)) {
                    return `${time}:00`;
                }
                return time;
            }
            const bookingDiffComment = {
                start_time: convertToFullTimeFormat(this.modalData.start_time),
                end_time: convertToFullTimeFormat(this.modalData.end_time),
                comment: this.modalData.booking_diff_comment
            };
            const postData = {
                start_time: convertToFullTimeFormat(this.modalData.start_time),
                end_time: convertToFullTimeFormat(this.modalData.end_time),
                booking_diff_comment: JSON.stringify(bookingDiffComment)
            };
            try {
                await axios.post(`/users/booking_divergence/${this.modalData.currentBookingId}`, postData, { withCredentials: true });
            } catch (error) {
                console.error('Fehler beim Senden der Buchungsabweichung:', error);
            }
            this.isBookingModalVisible = false;
        },
        async submitBooking() {
            if (!this.isCommentValid) {
                alert("Der Kommentar muss mindestens 5 Zeichen lang sein.");
                return;
            }

            function convertToFullTimeFormat(time) {
                if (time && /^\d{2}:\d{2}:\d{2}$/.test(time)) {
                    return time;
                }
                if (time && /^\d{2}:\d{2}$/.test(time)) {
                    return `${time}:00`;
                }
                return time;
            }

            const bookingDiffComment = {
                start_time: convertToFullTimeFormat(this.modalData.start_time),
                end_time: convertToFullTimeFormat(this.modalData.end_time),
                comment: this.modalData.booking_diff_comment
            };

            const postData = {
                start_time: convertToFullTimeFormat(this.modalData.start_time),
                end_time: convertToFullTimeFormat(this.modalData.end_time),
                booking_diff_comment: JSON.stringify(bookingDiffComment)
            };

            try {
                await axios.post(`/users/booking_divergence/${this.modalData.currentBookingId}`, postData, { withCredentials: true });
            } catch (error) {
                console.error('Fehler beim Senden der Buchungsabweichung:', error);
            }
            this.isBookingModalVisible = false;
        },
        async finishDay() {
            try {
                const planned_date = this.formatDate2PlannedDate(this.selectedDate);
                const postData = { planned_date };
                await axios.post(`/users/booking_finish/${this.user.id}`, postData, { withCredentials: true });
                this.refreshData();
                this.showSuccessAlert = true;
                this.alertMessage = 'Der Arbeitstag wurde erfolgreich abgeschlossen.';
            } catch (error) {
                this.showAlert = true;
                this.alertMessage = 'Es gab einen Fehler beim Abschließen des Arbeitstags.';
            } finally {
                window.location.reload();
            }
        },
        async handleNoDivergence() {
            await this.submitBookingNoDivergence();
            await this.finishDay();
            this.closeModal();
        },
        async handleBookingModalClose() {
            await this.submitBookingNoDivergence();
            await this.finishDay();
            this.closeModal();
        },
        closeModal() {
            this.isBookingModalVisible = false;
        },
        async initializeDashboard() {
            try {
                await this.getUser();
                await this.initializeWeek();
                await this.totalWorkingHours();
            } catch (error) {
                console.error("Error during initialization:", error);
            }
        },
        sanitizeComment() {
            this.modalData.booking_diff_comment = this.modalData.booking_diff_comment.replace(/[^a-zA-Z0-9äöüÄÖÜß\s]/g, '');
        },
        formatTime(field) {
            const timeValue = this.modalData[field];
            if (timeValue && timeValue.length === 5) {
                let [hours, minutes] = timeValue.split(':').map(Number);
                minutes = Math.floor(minutes / 15) * 15;
                this.modalData[field] = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
            }
        },
        isWeekend() {
            const today = new Date();
            const dayOfWeek = today.getDay();
            return dayOfWeek === 0 || dayOfWeek === 6;
        },
        isToday(date) {
            const today = new Date();
            return this.formatDate(date) === this.formatDate(today);
        },
        formatToLocaleDate(date) {
            const d = new Date(date);
            return d.toLocaleDateString('de-DE', { weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric' });
        },
        formatBookingDate(dateStr) {
            if (!dateStr) return '';
            const date = new Date(dateStr);
            if (isNaN(date.getTime())) return '';
            return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
        },
        logoutAction() {
            localStorage.clear();
            axios.post('/users/logout', {}, { withCredentials: true })
                .then(() => {
                    this.clearCookies();
                    this.$router.push('/');
                })
                .catch(error => {
                    console.error('Logout failed:', error);
                });
        },
        clearCookies() {
            document.cookie.split(";").forEach((c) => {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
        },
        extractChildName(fullString) {
            const parts = fullString.split('_');
            return parts.slice(0, 2).join('_');
        },
        formatDate2PlannedDate(date) {
            if (!(date instanceof Date)) date = new Date(date);
            const year = date.getFullYear().toString();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            return year + month + day;
        },
        necessaryWorkingHours() {
            return this.combinedData.reduce((sum, item) => sum + parseFloat(item.working_hours), 0);
        },
        async totalWorkingHours() {
            const planned_date = this.formatDate2PlannedDate(this.selectedDate);
            try {
                const response = await axios.get(`/users/get_total_booking_hours/${this.userId}/${planned_date}`, { withCredentials: true });
                this.bookingSummary = response.data?.bookingSummary || 0;
            } catch (error) {
                console.error('Error fetching total working hours:', error);
            }
        },
        async finishBookingForDay() {
            if (confirm("Info: Mit Klick auf OK wird der heutige Arbeitstag eingebucht und kann nicht mehr geändert werden.")) {
                this.isProcessingEntries = true;

                const plannedDate = this.formatDate2PlannedDate(this.selectedDate);
                for (const item of this.combinedData) {
                    this.openBookingModal(item, plannedDate, true);
                    this.isBookingModalVisible = true;
                    await new Promise(resolve => {
                        this.$watch('isBookingModalVisible', newValue => {
                            if (!newValue) resolve();
                        });
                    });
                }

                await this.finishDay();

                this.isProcessingEntries = false; // End processing
            }
        },
        checkAllBookingsFinished() {
            return this.combinedData.every(item => item.booking_finished);
        },
        async refreshData() {
            await this.getUser();
        },
        async getUser() {
            try {
                const response = await axios.get('/users/current', { withCredentials: true });
                this.userId = response.data.id;
                const today = new Date();
                const formattedDate = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + ('0' + today.getDate()).slice(-2);
                const userDataResponse = await axios.get(`/users/get_user_data/${this.userId}/${formattedDate}`, { withCredentials: true });
                if (userDataResponse && userDataResponse.data) {
                    this.user = userDataResponse.data.user || {};
                    this.combinedData = userDataResponse.data.combinedData || [];

                    if (userDataResponse.data.newsData) {
                        this.newsData = userDataResponse.data.newsData;
                        if (this.newsData.flag_news_read === 0) {
                            this.isNewsModalVisible = true;
                        }
                    }


                } else {
                    console.error('Unexpected response format:', userDataResponse);
                }
            } catch (error) {
                console.error('Error fetching combined data:', error);
                this.$router.push('/');
            }
        },
        goToPreviousWeek() {
            this.changeWeek(-1);
        },
        goToNextWeek() {
            this.changeWeek(1);
        },
        changeWeek(weeksToAdd) {
            this.selectedDate.setDate(this.selectedDate.getDate() + weeksToAdd * 7);
            this.initializeWeek();
        },
        async initializeWeek() {
            const startOfWeek = new Date(this.selectedDate);
            startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + (startOfWeek.getDay() === 0 ? -6 : 1));
            this.weekDays = [];
            for (let i = 0; 7 > i; i++) {
                const day = new Date(startOfWeek);
                day.setDate(day.getDate() + i);
                const events = await this.getEventsForDate(day);
                this.weekDays.push({ date: day, events });
            }
        },
        async getEventsForDate(date) {
            try {
                const formattedDate = this.formatDate(date);
                const response = await axios.get('/users/current', { withCredentials: true });
                const userId = response.data.id;
                const eventsResponse = await axios.get(`/users/get_calendar_data/${userId}`, { withCredentials: true });
                const eventsForDate = eventsResponse.data.filter(event => event.date === formattedDate);
                return eventsForDate.map((event, idCounter = 1) => ({
                    id: idCounter++,
                    title: event.title,
                    date: event.date,
                    message: event.message.replace(/;/g, '<br>'),
                }));
            } catch (error) {
                console.error('Error fetching events:', error);
                return [];
            }
        },
        formatDate(date) {
            if (!(date instanceof Date)) date = new Date(date);
            return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        }
    }
};
</script>


<style>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top: 8px solid #0b4e3e; /* Angepasste Farbe */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}




.dynamic-text {
    font-size: 1.2em;
    color: #333;
    font-weight: bold;
    text-align: center;
}

.table tbody tr:nth-child(even) {
    background-color: #d2d4d29e;
}

.table tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

.fixed-bottom-button {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    width: 30%;
    max-width: 400px;
    padding: 12px 16px;
    font-size: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0.85; /* Leicht durchsichtiger Button */
    background-color: rgba(59, 130, 246, 0.9); /* Durchsichtige Hintergrundfarbe */
    color: white;
    border: none;
    border-radius: 8px;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.fixed-bottom-button:hover {
    transform: translateX(-50%) scale(1.05);
    opacity: 1; /* Bei Hover-Effekt wird der Button komplett sichtbar */
}

/* Für mobile Geräte optimiert */
@media (max-width: 768px) {
    .fixed-bottom-button {
        bottom: 10px;
        width: 50%;
    }
}




.footer {
    padding: 1rem 0;
    border-top: 1px solid #e5e7eb;
}
.footer-content {
    font-size: 0.875rem;
}
</style>



